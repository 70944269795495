import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: "/home",
        meta: {
            title: '点控云'
        }
        // component: HomeView
    },

    //   {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    //   }

    {
        path: '/home',
        name: '首页',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
        children: [
            {
                path: 'allcs',
                name: '全场景智能客服',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Service/Allcs.vue'),
            },
            {
                path: 'call',
                name: '呼叫中心',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Service/Call.vue'),
            },
            {
                path: 'onlinecs',
                name: '在线客服',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Service/Onlinecs.vue'),
            },
            {
                path: 'roc',
                name: '工单中心',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Service/roc.vue'),
            },
            {
                path: 'allmarketing',
                name: '全流程营销系统',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Marketing/Allmarketing.vue'),
            },
            {
                path: 'erobot',
                name: '套电机器人',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Marketing/Erobot.vue'),
            },
            {
                path: 'collecsystem',
                name: '催收系统',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Marketing/Collecsystem.vue'),
            },
            {
                path: 'scrm',
                name: 'SCRM系',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Marketing/SCRM.vue'),
            },
            {
                path: 'trobot',
                name: '文本机器人',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Ai/Trobot.vue'),
            },
            {
                path: 'orobot',
                name: '外呼机器人',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Ai/Orobot.vue'),
            },
            {
                path: 'ahelp',
                name: '坐席辅助',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Ai/Ahelp.vue'),
            },
            {
                path: 'qinspection',
                name: '智能质检',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Ai/Qinspection.vue'),
            },
            {
                path: 'useranalysis',
                name: '用户行为数据分析工具',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Bdata/UserAnalysis.vue'),
            },
            {
                path: 'visbi',
                name: '可视化BI',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Bdata/VisBI.vue'),
            },
            {
                path: 'brandNumber',
                name: '品牌号码',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Communicate/BrandNumber.vue'),
            },
            {
                path: 'privacy',
                name: '隐私保护',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Communicate/Privacy.vue'),
            },
            {
                path: 'workPhone',
                name: '工作手机号',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Communicate/WorkPhone.vue'),
            },
            {
                path: 'salePhone',
                name: '工作手机号',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Communicate/salePhone.vue'),
            },
            {
                path: 'allScenarios',
                name: '全场景智能外呼',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Communicate/allScenarios.vue'),
            },
            {
                path: 'businessCard',
                name: '来电名片',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Communicate/BusinessCard.vue'),
            },
            {
                path: 'smartSms',
                name: '智能短信',
                component: () => import(/* webpackChunkName: "about" */ '../components/Goods/Communicate/SmartSms.vue'),
            },
            // 解决方案 行业解决方案模块
            {
                path: 'enterprise',
                name: '企业服务',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Industry/Enterprise.vue'),
            },
            {
                path: 'education',
                name: '教育与培训',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Industry/Education.vue'),
            },
            {
                path: 'finance',
                name: '金融与保险',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Industry/Finance.vue'),
            },
            {
                path: 'realestate',
                name: '房地产',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Industry/Realestate.vue'),
            },
            {
                path: 'newretail',
                name: '新零售',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Industry/Newretail.vue'),
            },
            {
                path: 'onehealth',
                name: '大健康',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Industry/Onehealth.vue'),
            },
            {
                path: 'beautyclinic',
                name: '医美',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Industry/Beautyclinic.vue'),
            },
            {
                path: 'furniture',
                name: '家具',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Industry/Furniture.vue'),
            },
            {
                path: 'internet',
                name: '互联网',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Industry/Internet.vue'),
            },
            {
                path: 'electroniccommerce',
                name: '电子商务',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Industry/Electroniccommerce.vue'),
            },
            // 解决方案 场景解决方案
            {
                path: 'marketing',
                name: '营销获客解决方案',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Scenario/Marketing.vue'),
            },
            {
                path: 'management',
                name: '全生命周期管理解决方案',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Scenario/Management.vue'),
            },
            {
                path: 'fullscene',
                name: '全场景客服解决方案',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Scenario/Fullscene.vue'),
            },
            // 帮助与支持
            {
                path: 'serviceEnsure',
                name: '服务保障说明',
                component: () => import(/* webpackChunkName: "about" */ '../components/Help/ServiceEnsure.vue'),
            },
            {
                path: 'SLA',
                name: 'SLA服务说明',
                component: () => import(/* webpackChunkName: "about" */ '../components/Help/SLA.vue'),
            },
            {
                path: 'HelpCener',
                name: '帮助中心',
                component: () => import(/* webpackChunkName: "about" */ '../components/Help/HelpCener.vue'),
            },
            {
                path: 'Developer',
                name: '开发者中心',
                component: () => import(/* webpackChunkName: "about" */ '../components/Help/Developer.vue'),
            },
            // 关于点控云
            {
                path: 'introduce',
                name: '公司简介',
                component: () => import(/* webpackChunkName: "about" */ '../components/About/Introduce.vue'),
            },
            {
                path: 'honor',
                name: '荣誉资质',
                component: () => import(/* webpackChunkName: "about" */ '../components/About/Honor.vue'),
            },
            {
                path: 'news',
                name: '企业新闻',
                component: () => import(/* webpackChunkName: "about" */ '../components/About/News.vue'),
            },
            {
                path: '/News/:id.html',
                name: '具体文章',
                component: () => import(/* webpackChunkName: "about" */ '../components/About/articles.vue'),
                meta: {
                    mateInfo: {
                        title: '娃哈哈',
                        keywords: 'wahahahh',
                        description: '啦啦啦啦啦啦'
                    }
                }
            },
            {
                path: 'join',
                name: '加入我们',
                component: () => import(/* webpackChunkName: "about" */ '../components/About/Join.vue'),
            },
            {
                path: 'contact',
                name: '联系我们',
                component: () => import(/* webpackChunkName: "about" */ '../components/About/Contact.vue'),
            },
            // 免费试用
            {
                path: 'mifi',
                name: '免费试用',
                component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Scenario/Mifi.vue'),
            },
            // 新闻资讯详情
            {
                path: 'detailed',
                name: '详情',
                component: () => import(/* webpackChunkName: "about" */ '../components/About/detailed.vue'),
            },
            // 移动端导航
            {
                path: 'anvi',
                name: '移动导航',
                component: () => import(/* webpackChunkName: "about" */ '../components/Home/anvi.vue'),
            }
        ]
    },
    {
        path: '/home/article',
        name: '新闻文章',
        component: () => import(/* webpackChunkName: "about" */ '../views/Article.vue'),
    },
    //h5页面
    {
        path: '/appMifi',
        name: 'h5免费试用',
        component: () => import(/* webpackChunkName: "about" */ '../components/Solution/Scenario/appMifi.vue'),
    },
    {
        path: '/privacyPolicy',
        name: '隐私政策',
        component: () => import('../components/Privacy/index.vue'),
    },
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

//需要添加内容
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router


router.beforeEach((to, from, next) => {
    //    路由发生变化改变description和keyword
    if (to.meta.content) {
        const head = document.getElementsByTagName('head')
        const meta = document.createElement('meta')
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content
        head[0].appendChild(meta)
    }
    // 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = '点控云'
    }
    next()
})
