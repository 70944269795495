class Auth {
  constructor() {
    this.TokenKey = 'DkYun-Token'
  }

  getToken() {
    return window.localStorage.getItem(this.TokenKey)
  }

  setToken(token) {
    return window.localStorage.setItem(this.TokenKey, token)
  }

  removeToken() {
    return window.localStorage.removeItem(this.TokenKey)
  }
}

export default new Auth()
