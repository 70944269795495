import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    flagtab:'',
    style:'',
    tab:''
  },
  getters: {
  },
  mutations: {
    saveCurrDbSource(state,data){
      console.log("data",data)
      state.flagtab=data
    },
    backt(state,data){
      state.style=data
    },
    Fan(state,data){
      // console.log(data);
      state.tab=data
    }
  },
  actions: {
  },
  modules: {
  }
})
