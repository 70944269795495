<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import { tdklist } from "@/api/home.js";

export default {
  // updated() {
  //   for (let item of document.getElementsByTagName("head")[0].children) {
  //     console.log(item,"iiiiiiiiiiiiiiiiiiiiiiiiii")
  //     if (item.name == "keywords") {
  //       item.content =
  //         "点控云，点控云（北京），点控云智能科技有限公司，天润融通，沃丰科技，容联七陌，外呼系统，呼叫中心，呼叫中心系统，智能客服，AI外呼机器人，合力亿捷，智齿科技、中通天鸿、祥云、腾讯启点、阿里通信";
  //     }
  //     if (item.name == "description") {
  //       item.content =
  //         "点控云（北京）智能科技有限公司是一家以AI、通讯技术为核心的企业，公司专注于为客户提供新一代的智能全客服、智能营销的全媒体联络中心解决方案。作为企业全媒体联络中心解决方案提供商，通过不断的技术创新，先后推出面向企业服务、数字化转型方向的多维度产品。通过全场景产品矩阵为企业搭建完善的客户服务体系，实现以服务为导向的智能化转型，助力企业降本增效，帮助企业提升经营效率，实现公司自身价值。";
  //     }
  //   }
  // },
  mounted() {
    // tdklist().then((res) => {});
  },
};
</script>
<style lang="less">
// body {
// @media screen and (max-width: 1520px){
//   width: 1520px;
// }
// }
.embed-chat-container {
  right: 80px !important;
}
</style>
