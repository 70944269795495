export const debounce = (fn, delay) => {
    let timer;
    return function() {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn();
        }, delay);
    }
}

// 动态计算 chart tooltip 位置
export const handleTooltipPosition = (point, params, dom, rect, size) => {
    const [mouseX, mouseY] = point
    const [tooltipWidth, tooltipHeight] = size['contentSize']
    console.log(point, size['contentSize'])
    let [posX, posY] = [0, 0]; // tooltip的显示位置
    // x位置判断
    if (mouseX < tooltipWidth) {
        // 如果左边放不下，tooltip的左侧位置=鼠标X
        posX = mouseX;
    } else {
        posX = mouseX - tooltipWidth;
    }
    if (mouseY < tooltipHeight) {
        // 如果上边放不下，tooltip的上侧位置=鼠标Y
        posY = mouseY;
    } else {
        posY = mouseY - tooltipHeight;
    }
    return [posX + tooltipWidth, posY + tooltipHeight/2];
}

//get请求的参数转换
export const tansParams = (params) => {
    let result = ''
    for(const propName of Object.keys(params)) {
        const value = params[propName]
        let part = encodeURIComponent(propName) + '='
        if(value !== null && value !== '' && typeof value !== 'undefined') {
            if(typeof value === 'object') {
                for(const key of Object.keys(value)) {
                    if(value[key] !== null && value[key] !== '' && typeof value[key] !== 'undefined') {
                        let cParams = propName + '[' + key + ']'
                        let subPart = encodeURIComponent(cParams) + '='
                        result += subPart + encodeURIComponent(value[key]) + '&'
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&"
            }
        }
    }
    return result
}

// list转树结构
export const listToTree = (data, id, pid) => {
    // 1、删除所以的children防止多次调用
    data.forEach(item => {
        delete item.children
    })
    let map = {}
    // 2、将数据存储为以id为 KEY 的map索引数据列
    data.forEach(function (item) {
        map[item[id]] = item
    })
    console.log('map', map)
    let val = []
    data.forEach(function(item) {
        let parent = map[item[pid]]
        if(parent) {
            (parent.children || (parent.children = [])).push(item)
        } else {
            val.push(item)
        }
    })
    console.log(val)
    return val
}

// 时间转为毫秒
export const timeToMillisecond = (str) => {
    if (!str) return
    let ary = str.split(':')
    //比如时间是7天2小时14分36秒
    let dateInfo = {
        hours: ary[0],
        mins: ary[1],
        secs: ary[2],
    };
    return Number(dateInfo.hours * 3600 * 1000) +
        Number(dateInfo.mins * 60 * 1000) +
        Number(dateInfo.secs * 1000);
}
// 十分秒转化成秒
export const timeToSecond = (str) => {
    if (!str) return
    let ary = str.split(':')
    //比如时间是7天2小时14分36秒
    let dateInfo = {
        hours: ary[0],
        mins: ary[1],
        secs: ary[2],
    };
    return Number(dateInfo.hours * 3600) +
        Number(dateInfo.mins * 60 ) +
        Number(dateInfo.secs);
}

export const hasPermission = (identifying) => {
    let ary = JSON.parse(localStorage.getItem('authInfo'))['permissions']
    let flag = ary.some(item => {
        return item === identifying
    })
    return  !flag
}
//时间戳转换为yyyy-mm-dd hh:mm:ss
export const formatDate=(date) =>{
    var date = new Date(date);
    var YY = date.getFullYear() + "-";
    var MM = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh =  (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
    var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return YY + MM + DD + " " + hh + mm + ss;
}