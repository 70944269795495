import request from '@/utils/request'
export function getlist() {
    return request({
        url: '/api/website/page/getImageGrab',
        method: 'get',
    })
}
//获取tdk
export function tdklist() {
    return request({
        url: '/api/website/page/getTdk',
        method: 'get',
    })
}
//获取品牌logo
export function tdklogo() {
    return request({
        url: '/api/website/page/getLogo',
        method: 'get',
    })
}
//获取访客信息
export function viewss(source) {
    return request({
        url: `/api/website/page/visitor/${source}`,
        method: 'get',
    })
}

//发送在线咨询的信息
export function sendOnlineInfo(data){
    return request({
        url:'/api/website/page/saveLeave',
        method:'post',
        data:data
    })
}
