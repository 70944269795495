import axios from 'axios'
import auth from '@/utils/auth.js'
import cache from '@/utils/cache.js'
import { tansParams } from '@/utils/tools.js'
import errorCode from '@/utils/errorCode.js'
import { Message } from 'element-ui';
// import { MessagePlugin, DialogPlugin } from 'tdesign-vue-next'
// import { useUserStore } from '@/pinia/user.js'
// import router from "../routes";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 是否显示重新登录
export let isRelogin = { show: false }

const service = axios.create({
  // baseURL: import.meta.env.VITE_APP_API,
  // baseURL:'http://website-admin-test.dk-yun.cn/',
  // baseURL: 'http://192.168.1.29:8081',
  baseURL:'https://admin.dk-yun.cn',
  timeout: 30 * 1000
})
// console.log('打印看看代理地址', import.meta.env.VITE_APP_API);
//请求拦截
service.interceptors.request.use((config) => {
  console.log('拦截打印看看data', config);
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false

  if (auth.getToken() && isToken) {
    config.headers['Authorization'] = auth.getToken()
  }

  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params)
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }

  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.setJSON('sessionObj', JSON.stringify(requestObj))
    } else {
      const s_url = sessionObj.url
      const s_data = sessionObj.data
      const s_time = sessionObj.time
      const interval = 1000

      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交'
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.setJSON('sessionObj', JSON.stringify(requestObj))
      }
    }
  }
  return config
}, (error) => {
  console.log(error)
  Promise.reject(error)
})

//响应拦截
service.interceptors.response.use((res) => {
  // const userStore = useUserStore()
  let code = null
  if (res.status === 200) {
    console.log('得到的code', res.data.code);
    code = res.data.code
  } else {
    code = res.status
  }

  const msg = errorCode[code] || res.data.msg || errorCode['default']

  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }

  // if (code === 1) {
  //     if (!isRelogin.show) {
  //         isRelogin.show = true
  //         const confirmDia = DialogPlugin.confirm({
  //             ref: 'hh',
  //             header: '提示',
  //             body: '当前页面已失效，请重新登录',
  //             confirmBtn: {
  //                 content: '确定',
  //                 theme: 'primary',
  //                 loading: false,
  //             },
  //             theme: 'warning',
  //             onConfirm: () => {
  //                 userStore.logOut()
  //                 isRelogin.show = false
  //                 // location.href = '/login';

  //                 router?.push('/login')
  //                 // 请求成功后，销毁弹框
  //                 confirmDia.destroy();
  //             }
  //         })
  //     }
  //     return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  // }
  else if (code === 500) {
    if (msg) {
      Message({
        message: `${msg}`,
        type: 'error'
      });
    }

    // MessagePlugin.error(msg)
    return Promise.reject(new Error(msg))
  } else if (code !== 200 && code !== 0) {
    // MessagePlugin.error(msg)
    if (msg) {
      Message({
        message: `${msg}`,
        type: 'error'
      });
    }
    return Promise.reject('error')
  } else {
    return res.data
  }
}, (error) => {
  let { message } = error;
  if (message == "Network Error") {
    message = "后端接口连接异常";
  }
  else if (message.includes("timeout")) {
    message = "系统接口请求超时";
  }
  else if (message.includes("Request failed with status code")) {
    message = "系统接口" + message.substr(message.length - 3) + "异常";
  }
  if (message) {
    Message({
      message: `${message}`,
      type: 'error'
    });
  }
  // MessagePlugin.error(message)
  return Promise.reject(error)
})

export default service
