class  Cache {
    constructor() {}

    setSession(key, value) {
        if(!window.sessionStorage) return

        if(key != null && value != null) window.sessionStorage.setItem(key, value)
    }

    getSession(key) {
        if(!window.sessionStorage || key == null) return null

        return window.sessionStorage.getItem(key)
    }

    setJSON(key, value) {
        if(value != null) this.setSession(key, value)
    }

    getJSON(key) {
        const value = this.getSession(key)

        if(value != null) return JSON.parse(value)
    }

    removeSession(key) {
        window.sessionStorage.removeItem(key)
    }

    clear() {
        window.sessionStorage.clear()
        window.localStorage.clear()
    }

    setLocalstorage(name, value){
        if(name != null && value != null) window.localStorage.setItem(name, JSON.stringify(value))
    }

    getLocalstorage(name){
        if(name != null) return window.localStorage.getItem(name)
    }

    removeLocalstorage(name) {
        if(name != null) window.localStorage.removeItem(name)
    }
}

export default new Cache()
